<template>
        <div class="w-full py-6 md:py-8 flex h-full flex-wrap md:flex-nowrap md:gap-24">
          <div class="md:w-5/12 w-full h-full">
            <div class="border-4 border-dark shadow-2xl p-4 md:p-6 flex flex-wrap justify-center  rounded-xl">
              <div class="flex flex-col justify-center items-center">
                  <p class="font-secondary text-3-4xl mb-2">{{ category }}</p>

                  <svg width="70" height="16" viewBox="0 0 70 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M50.5875 0L69.1588 10.8708L66.3134 15.9716L50.4742 6.70003L34.9642 15.0621L19.4218 6.6825L2.74625 16L0 10.8425L19.3734 0.0175261L34.9642 8.42325L50.5875 0Z" fill="#F19F00"/>
                  </svg>

              </div>

              <div class="flex w-full mt-3 justify-between" v-if="booking">
                <!--div class="w-auto text-center">
                    <p class="uppercase text-md block text-center">single</p>
                    <span class="uppercase font-primary font-black text-2xl text-primary">{{booking.prices.sgl_total_price.toFixed(0)}} USD</span>
                </div>
                <div class="w-auto text-center">
                    <p class="uppercase text-md block text-center">double</p>
                    <span class="uppercase font-primary font-black text-2xl text-primary">{{booking.prices.dbl_total_price.toFixed(0)}} USD</span>
                </div>
                <div class="w-auto  text-center">
                    <p class="uppercase text-md block text-center">triple</p>
                    <span class="uppercase font-primary font-black text-2xl text-primary">{{booking.prices.tpl_total_price.toFixed(0)}} USD</span>
                </div-->
              </div>

              <div class="flex w-full mt-6 justify-center">
                <a class="font-light border border-dark bg-white text-md py-2 px-4 w-full hover:bg-primary hover:text-dark transition-all hover:border-primary rounded-lg text-center" @click="viewHotels">View {{category}} Hotels</a>
              </div>

            </div>  
          </div>
          <div class="md:w-7/12 w-full mt-6 md:mt-0" v-if="booking">
            <div class="border-4 border-light-gray bg-light-gray shadow-xl p-4 md:p-6 flex flex-wrap justify-start flex h-full items-start rounded-xl">
              <div>
                <p class="font-secondary text-2xl mb-2">Start you adventure with us!</p>
                <p class="text-dark font-light text-2xl md:pr-20">If you are happy with the proposed quote, you can make a deposit of 
                  <b class="font-bold text-primary">{{ payment }} USD</b>, 
                  which represents 30% of the cost of your trip.</p>

              </div>
              <div class="self-end flex flex-wrap w-full md:flex-nowrap md:mt-3 ">
                  <a class="font-light border border-primary bg-primary text-dark text-md py-2 px-4 w-full hover:bg-dark hover:text-white transition-all hover:border-dark rounded-lg text-center mr-3 my-4 md:my-0">Book now</a>
                  <img src="@/assets/trip/img/cards.svg" class="mx-auto">
              </div>
            </div>
          </div>
        </div>

</template>
<script>
import * as _ from 'lodash';
export default {
  name: 'PriceBox',
  props: {
  },
  computed: {
    hotels () {
      return this.$store.state.hotels;
    },
    booking () {
      return this.$store.state.booking;
    },
    category () {
      if (this.hotels) {
        let rows = _.uniqBy(this.hotels, (r) => r.ClassCode);
        if (rows.length == 1) {
          return rows[0].ClassName;
        }
      }
      return '';
    },
    payment () {
      if (this.booking && this.booking.prices) {
        let v = this.booking.prices.tpl_total_price * 0.3 
        return v.toFixed(0);
      } 
      return ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    viewHotels () {
      let route = this.$router.resolve({ name: 'Hotels', params: this.$route.query })
      route.href = route.href + '?' + new URLSearchParams(this.$route.query).toString();
      window.open(route.href, '_blank');
    }
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>