<template>
  <section class="bg-light-gray py-6 md:py-12">
    <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-12 my-6 justify-center">
      <div class="w-full  md:pr-20">
          <h2 class="font-secondary text-2xl md:text-3xl mb-8">
        
        FAQs
        </h2>
      </div>
      <div class="mt-6 md:mt-8 w-full flex flex-wrap">
          <div id="accordion-collapse-faq" data-active-classes="text-primary" data-accordion="collapse" class="grid md:grid-cols-2 gap-5 w-full">

            <div v-for="(item, index) in items" :key="item.id" class="w-full">
                <div class="border border-dark rounded-lg overflow-hidden">
                  <h2 :id="'accordion-collapse-heading-faq-' + item.id">
                    <button type="button" class="flex items-center bg-white justify-between w-full p-5 font-medium rtl:text-right gap-4"  
                      @click="toggle(index)"
                      :class="{'text-dark': activeIndex != index, 'text-primary': activeIndex === index}"
                      :aria-expanded="activeIndex === index" 
                      :aria-controls="'accordion-collapse-body-' + item.id">
                      <span class="text-lg hover:text-primary font-bold">{{ item.title }}</span>
                      <svg :class="{'rotate-180': activeIndex === index}" data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                      </svg>
                  </button>
                  </h2>
                  <div :id="'accordion-collapse-heading-body-' + item.id" :class="{'hidden': activeIndex != index}" 
                    :aria-labelledby="'accordion-collapse-body-faq-faq-' + item.id" v-show="activeIndex === index">
                    <div class="p-5 pt-0  bg-white ">
                      <p class="mb-2 text-dark">{{ item.content }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
name: 'FAQsa',
methods: {
    toggle(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
    }
},
data() {
    return {
        activeIndex: null,
        items: [
          { id: 1, title: 'When is the final balance due?', 
            content: 'We require a 50% deposit of the total cost for land arrangements plus full payment for the flights. The remaining balance will be due 45 days before your trip.'
          },
          { id: 2, title: 'Can I split the payment?', 
            content: 'Yes, payments can be split so that different travelers can pay their share. If you wish to split the payment across different dates, please discuss this with your travel advisor. It will also depend on the timing of your trip.'
          },
          { id: 3, title: 'Is there a credit card charge?',
            content: 'Once you confirm the trip, we will take a few days to process your booking and send you the Pre-Final Confirmation with hotel information. At this stage, we will introduce you to your Guest Relations Specialist at your destination, who will support you before and during your trip.'
          },
          { id: 4, title: 'What happens next?', 
            content: 'There is no additional charge for paying with a credit card.'
          }
        ]      
    };
}
}
</script>
