import { createStore } from 'vuex';
import * as _ from 'lodash';
import { HTMLToJSON } from 'html-to-json-parser'; // ES6

const s3 = process.env.VUE_APP_S3;
// Create a new store instance.
const store = createStore({
  state () {
    return {
      booking: null,
      query: {},
      services: [],
      agentName: null,
      agentDescription: null,
      agentEmail: null,
      agentPhone: null,
      agentMeet: null,
      hotels: [],
      sellerImg: null,
      coverImg: null,
      tripName: null,
      paxName: null,
      loaded: false,
      languageNotes: []
    }
  },
  mutations: {
    setBooking (state, values) {
      if (values) {
        state.services = Object.assign([], _.filter(values, (r) => {
          if (r.ServiceTypeCode == 'IN') return false;
          if (r.ServiceTypeCode == 'AC') return false;
          if (r.ServiceTypeCode == 'XF') return false;
          if (r.ServiceTypeCode == 'FT') return false;
          //if (!r.Descripcion && !r.Titulo) return false;
          return true;
        }));
        state.hotels = Object.assign([], _.filter(values, (r) => {
          if (r.ServiceTypeCode == 'AC') return true;
          return false;
        }))
        let cover = _.find(values, (r) => r.ServiceTypeCode == 'DE');
        if (cover) {
          state.coverImg = `${s3}/OPT_ID.${cover.OPT_ID}.0.png`;
        }

      }
    },
    setQuery (state, value) {
      state.query = Object.assign({}, value);
    },
    setSeller (state, seller) {
      state.sellerImg = `${s3}/OPT_ID.${seller.OPT_ID}.0.png`;
      state.agentDescription = seller.DescripcionVendedor;
      state.agentPhone = seller.CelVendedor;
      HTMLToJSON(seller.CelVendedor.replace(/<br>/g, '<br/>'), false).then((v) => {
        if (v.content && v.content[0] && v.content[0].content) {
          state.agentPhone = _.find(v.content[0].content, (r) => !r.type);
        }
      });
      state.agentName = seller.DESCRIPTION.replace('Vendedor: ', '');
      HTMLToJSON(seller.CalendarVendedor.replace(/<br>/g, '<br/>'), false).then((v) => {
        if (v.content && v.content[0] && v.content[0].content) {
          state.agentMeet = _.find(v.content[0].content, (r) => !r.type);
        }
      });
      HTMLToJSON(seller.CorreoVendedor.replace(/<br>/g, '<br/>'), false).then((v) => {
        if (v.content && v.content[0] && v.content[0].content) {
          state.agentEmail = _.find(v.content[0].content, (r) => !r.type);
        }
      });

    },
    setNames (state, values) {
      values.forEach(row => {
        if (row.CATEGORY == 'PAX') state.paxName = row.MESSAGE_TEXT;
        if (row.CATEGORY == 'VIA') state.tripName = row.MESSAGE_TEXT;
      });
    },
    setLanguageNotes (state, values) {
      state.languageNotes = values;
    }
  }
})

export default store;
