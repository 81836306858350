<template>
    <section class="w-full">
        <div v-for="(item, index) in items" :key="item.id" class="">
            <h2 :id="'accordion-collapse-heading-' + item.id">
                <button type="button" 
                        class="flex items-center bg-white justify-between w-full px-5 py-4 font-medium rtl:text-right border-t border-b border-gray-200 gap-4"  
                        @click="toggle(index)"
                        :class="{'text-dark': activeIndex != index, 'text-primary': activeIndex === index}"
                        :aria-expanded="activeIndex === index" 
                        :aria-controls="'accordion-collapse-body-' + item.id">
                    <span class="text-lg hover:text-primary font-bold">
                        <svg class="mr-3 inline-block" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.368123 5.62305L6.60845 9.08631C6.8248 9.20579 7.08636 9.20579 7.30271 9.08631L13.5366 5.62305C14.0274 5.35019 14.0274 4.64462 13.5366 4.37176L7.3011 0.913339C7.08636 0.793861 6.8248 0.793861 6.60845 0.913339L0.368123 4.37176C-0.122708 4.64462 -0.122708 5.35019 0.368123 5.62305Z" fill="#F19F00"/>
                        </svg> 
                        {{ item.title }}
                    </span>
                    <svg :class="{'rotate-180': activeIndex === index}" class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                    </svg>
                </button>
            </h2>
            <div :id="'accordion-collapse-body-' + item.id" 
                  :class="{'hidden': activeIndex !== index}" 
                  :aria-labelledby="'accordion-collapse-heading-' + item.id">
                <div v-html="item.content"></div>
            </div>
        </div>
    </section>
</template>

<script>
import * as _ from 'lodash';
var moment = require('moment');
export default {
  name: 'PriceTerms',
  mounted () {
    this.getFlightImage();
  },
  methods: {
    toggle(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
    },
    async getFlightImage () {
      await Promise.resolve(import('@/assets/trip/img/avion.png')).then((value) => {
          this.flightImage =  value.default;
      });
    }
  },
  data() {
      return {
          activeIndex: null,
          flightImage: null,
      };
  },
  computed: {
    hotelsList () {
      return this.$store.state.hotels;
    },
    hotels () {
      let res = [];
      res = _.map(this.hotelsList, (e) => {
        let checkin = moment(e.CheckIn, 'YYYY-MM-DD');
        let checkout = moment(e.CheckOut, 'YYYY-MM-DD');
        let nights = checkout.diff(checkin, 'day')
        return {
          dateString: e.CheckIn,
          nights,
          location: e.LocationCode,
          locationName: e.LocationName,
        }
      });
      res.sort((a, b) => {
        if (a.dateString > b.dateString) return 1;
        if (a.dateString < b.dateString) return -1;
        return 0
      });
      return res;
    },
    items () {
      var values = _(this.hotels).groupBy('locationName').map((objs, key) => ({
              'locationName': key, 'nights': _.sumBy(objs, 'nights') })).value();
      let nights = '';
      for (let h of values) {
        nights += `<li>${h.nights} Night${h.nights>1? 's': ''} in ${h.locationName}</li>`;
      }

      let res = [
            { id: 1, title: 'Pricing', content: `
              <div class="p-5 pt-0 border-none border-b-0 border-gray-200 ">
                <p class="text-dark">
                  <ul class="list-disc pl-4">
                    <li>Prices in US Dollars.</li>
                    <li>Prices are excluding VAT from the accommodation in Hotels in Argentina (except Ushuaia). Guests must provide copy of their passport so as to prove they are not residents in Argentina, also they may be asked to show during their check in the stamp of entry to Argentina. If these conditions aren´t complied they will be asked to pay the VAT from the accommodation.  </li>
                    <li>Please check our <b><u><a href="https://sayhueque.com/terms-conditions/" target="_blank">TERMS & CONDITIONS.</a></u></b></li>
                    <li>This quote is valid for 30 days since the day you receive it, after this month it is subject to changes in pricing.</li>
                  </ul>
                </p>
              </div>
              `
            },
            { id: 2, title: 'Included', content: `
              <div class="p-5 pt-0 border-none border-b-0 border-gray-200  ">
                <p class="text-dark leading-tight">
                  <ul class="list-disc pl-4">
                    ${nights}
                    <li>Transfers & Meals mentioned in the itinerary.</li>
                    <li>Tours described in the itinerary.</li>
                    <li>Bilingual guides (English/Spanish) when mentioned in the itinerary.</li>
                  </ul>
                </p>
              </div>
              `
            },
            { id: 3, title: 'Not Included', content: `
              <div class="p-5 pt-0 border-none border-b-0 border-gray-200  ">
                <p class="text-dark leading-tight">
                  <ul class="list-disc pl-4">
                    <li>Fees & Mandatory Documentation (For more information click <b><a href="https://sayhueque.com/travel-guide/argentina/#tab-7" target="_blank">HERE</a></b>).Except when mentioned in the itinerary.</li>
                    <li>International or Domestic Flights (quoted separated if requested).</li>
                    <li>Travel / Medical Insurance - To get a free quotation please follow this link: <b><a href="https://www.withfaye.com/quote/destination" target="_blank">Travel Insurance</a></b>.</li>
                    <li>Tips: For more information click <b><a href="https://sayhueque.com/travel-guides/" target="_blank">HERE</a></b>.</li>
                  </ul>
                </p>
              </div>
              `
            },
      ];
      if (this.flightImage) {
        res.push(
          { id: 4, title: 'Flights needed for this itinerary', content: `
                <div class="p-5 border-b border-gray-200 ">
                    <p class="mb-2 text-dark uppercase font-bold text-lg mb-3">
                        <img src="${this.flightImage}" width="34px" class="mr-1 inline-block"> Domestic flights</p>
                    <p class="text-dark">
                        <ul class="list-disc pl-4">
                            <li>Prices in US Dollars.</li>
                            <li>Prices are excluding VAT from the accommodation in Hotels in Argentina (except Ushuaia). Guests must provide copy of their passport so as to prove they are not residents in Argentina, also they may be asked to show during their check in the stamp of entry to Argentina. If these conditions aren´t complied they will be asked to pay the VAT from the accommodation.</li>
                            <li>Please check our <b><u>TERMS & CONDITIONS.</u></b></li>
                            <li>This quote is valid for 30 days since the day you receive it, after this month it is subject to changes in pricing.</li>
                        </ul>
                    </p>
                </div>`
            }

        )
      }
      return res;      
    }
  }
}
</script>
