<template>
    <section>
      <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-20">

        <div class="w-full md:w-6/12 md:pr-20 mb-12 md:mb-0">

          <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            Why this trip is perfectly matched for you
          </h2>

          <p class="text-dark font-normal text-md">
            Patagonia is vast and makes up almost half of both Chile and Argentina boasting some of the most picturesque natural beauty you will ever see. Patagonia has a handful of very unique National Parks for you to enjoy – most you will find featured in this Patagonia Destination Guide series, including Glacier, Torres del Paine, Nahuel Huapi and Tierra del Fuego National Parks. These treasures offer its visitors jagged mountain peaks, dramatic ice fields, brutal glaciers, unpredictable weather and some of the best trekking in the world.
          </p>
        </div>

        <div class="w-full md:w-6/12 border-2 border-primary rounded-xl pt-6 pb-12 md:pb-12 px-6 md:px-12">

          <div class=" relative text-center " style="top: -2.5rem">
            <h3 class="text-2xl bg-white px-3 text-primary font-primary font-black inline-block mx-auto text-center">Why Say Hueque</h3>
          </div>

          <div class="grid md:grid-cols-2 gap-6 justify-center items-center">

            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/iconocasa.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">Local know best</span>
            </div>

            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/diamante.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">Elite reputation</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/mano.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">24/7 here for you</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/medalla.png" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">Best value</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/recicla.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">Sustainable commitment</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/pesos.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">Local economies</span>
            </div>
            
          </div>

        </div>

      </div>
    </section>  

</template>
<script>
export default {
  name: 'SectionWhy',
  props: {
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
